import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import logo from "./logo.svg";
import { Peer } from "peerjs";
import * as snex from "snex";
import * as md5 from "js-md5";

import "./App.css";
import { decode } from "base64-arraybuffer";

let iframeUrl = "";
let peerConfig: any | undefined = undefined;
if (process.env.NODE_ENV !== "production") {
  iframeUrl = "http://192.168.1.149:1234/";
  peerConfig = {
    host: "192.168.1.149",
    port: 9000,
    path: "/myapp",
    secure: false,
  };
} else {
  iframeUrl = "https://snes.possiblenull.com/";
  peerConfig = undefined;
}

const files: Record<string, Map<number, string>> = {};

const handleCompleteFile = (
  key: string,
  numChunks: number,
  chunks: Map<number, string>
) => {
  let allData = [];
  for (let i = 0; i < numChunks; ++i) {
    const chunk = chunks.get(i);
    if (chunk === undefined) {
      throw new Error(`Invalid chunk ${i}`);
    }
    allData.push(chunk);
    chunks.delete(i);
  }

  const fileData = allData.join("");
  allData = [];

  const fileName = decodeURI(key);
  console.log("FULL FILE", fileName, fileData.length);
  function checksum(s: string) {
    var chk = 0x12345678;
    var len = s.length;
    for (var i = 0; i < len; i++) {
      chk += s.charCodeAt(i) * (i + 1);
    }

    return (chk & 0xffffffff).toString(16);
  }
  console.log("CHECKSUMMED", checksum(fileData));
  const dataBuffer = decode(fileData);
  console.log("MD5 CHECKSUM", md5(dataBuffer));
  // console.log(dataBuffer);
  return dataBuffer;
};

type RawFileMessage = {
  key: string;
  fileData: string;
  numChunks: number;
  chunk: number;
};

const handleChunk = ({ key, fileData, chunk, numChunks }: RawFileMessage) => {
  const map = files[key] ?? new Map<number, string>();
  files[key] = map;
  map.set(chunk, fileData);
  if (map.size === numChunks) {
    delete files[key];
    return handleCompleteFile(key, numChunks, map);
  }
  console.log("chunk", Array.from(map.keys()), numChunks);
  return null;
};

function App() {
  const castId = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get("castId");
  }, []);
  console.log("CAST ID", castId, window.location.search);
  console.log("CAST ID", castId);
  console.log("CAST ID", castId);
  console.log("CAST ID", castId);
  console.log("CAST ID", castId);
  const frame = useRef<null | HTMLIFrameElement>(null);
  const [debug, updateDebug] = useState("");
  const setDebug = useCallback(
    (value: string) => {
      if (
        window.location.href.includes("192") ||
        window.location.href.includes("localhost")
      ) {
        updateDebug(value);
      }
    },
    [updateDebug]
  );

  const refCode = useMemo(() => {
    if (castId != null) {
      return null;
    }

    return uuidv4().substring(0, 4).toUpperCase();
  }, [castId]);

  useEffect(() => {
    const peerId = refCode ? refCode.repeat(15) : castId!;
    console.log("peerIdCode", peerId);
    const peer = new Peer(peerId, peerConfig);
    peer.on("open", (id) => {
      console.log("ID", id);
      setDebug(`id: ${id}`);
    });
    peer.on("connection", (conn) => {
      conn.on("data", (data) => {
        // Will print 'hi!'
        // console.log(data);
        if ((data as { type?: string }).type !== "rawFile") {
          frame.current?.contentWindow?.postMessage(data, "*");
          setDebug(JSON.stringify(data));
        } else {
          setDebug(`rawFile ${(data as { fileData: string }).fileData.length}`);
          const fileMessage = data as RawFileMessage;
          const file = handleChunk(fileMessage);
          if (file !== null) {
            const fullFileMessage = {
              type: "rawFile",
              fileArray: file,
              key: fileMessage.key,
            };
            frame.current?.contentWindow?.postMessage(fullFileMessage, "*", [
              fullFileMessage.fileArray,
            ]);
          }
        }
      });
      conn.on("open", () => {
        conn.send("hello!");
      });
    });
    setDebug("waiting");
    return () => peer.destroy();
  }, [refCode, castId]);

  return (
    <div className="App">
      <iframe
        style={{ width: "calc(98vh * 4 / 3)", height: "98vh" }}
        src={iframeUrl}
        ref={frame}
        frameBorder="0"
      />
      <pre style={{ position: "absolute", top: 0, left: 0, color: "white" }}>
        {debug}
      </pre>
      <pre
        style={{
          position: "absolute",
          top: 30,
          fontSize: 24,
          left: 0,
          color: "white",
        }}
      >
        {refCode?.split("")?.join(" - ")}
      </pre>
    </div>
  );
}

export default App;
